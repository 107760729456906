
<template>
  <div class="members groups" data-app>
    <div class="row page_header_all">
      <div class="col-md-6 col-12 right_content">
        <div class="page_title">
          <div class="title_icon">
            <img src="/media/svg/plus.svg" />
          </div>
          <h6 class="title">قائمة الأعضاء</h6>
        </div>
        <div class="page_input_search position-relative">
          <input
            @input="getAllUser"
            v-model="search_word"
            class="name_search"
            placeholder="بحث بالاسم"
          />
          <button class="search-icon">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div class="col-md-6 action_buttons">
        <button
          class="btn    btn_Green"
          @click="
            popupAddUser = true;
            action = 'add';
            form = {};
          "
        >
          <span> إضافة عضو</span>
        </button>
      </div>
    </div>
    <div class="member-tablet-view mt-4">
      <ListData :usersList="AllUsers" :SelectUser="SelectUser" />
      <paginate
        v-if="totalPages > 1"
        v-model="page"
        :pageCount="totalPages"
        :page-range="15"
        :clickHandler="clickCallback"
        :prevText="'الصفحه السابقة'"
        :nextText="'الصفحه التالية'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>

    <AddMembers
      v-if="popupAddUser"
      :popupAddUser="popupAddUser"
      :closePopup="closePopup"
      :getAllUser="getAllUser"
      :action="action"
      :selectedUser="selectedUser"
    />
    <!-- Remove popup -->
    <div class="centerx">
      <vs-popup class="holamundo" title="حذف عضو" :active.sync="popupDelete">
        <div class="add_group form_info text-center">
          <p>هل انت متأكد من حذف {{ selectedUser.name }} ؟</p>
          <div class="popoup_footer justify-content-end d-flex">
            <button
              class="   btn_Green"
              @click="deleteUser(selectedUser.id)"
            >
              <span>حذف</span>
            </button>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import users from "../../http/requests/users";
import ListData from "@/components/users/List";
import AddMembers from "@/components/users/AddMembers";
export default {
  name: "Members",
  components: { ListData, AddMembers },
  data() {
    return {
      search_word: null,
      totalPages: 0,
      page: 1,
      keepScrean: false,
      previewImage: null,
      user_photo: "",
      form: {},
      error_mesage: "",
      ction: "",
      AllRoles: [],
      AllUsers: [],
      value: null,
      action: "",
      popupAddUser: false,
      popupDelete: false,
      selectedUser: {},

      data: [],
    };
  },
  methods: {
    closePopup() {
      this.popupAddUser = false;
    },
    clickCallback(pageNum) {
      this.page = pageNum;
      this.getAllUser();
    },

    handlePhonenumber(mobile) {
      this.form.mobile = mobile;
    },
    handlePhoneCountry(info) {
      this.form.country_code = info.dialCode;
    },

    getUserRoles() {
      this.AllRoles = [];

      users
        .getuserRoles()
        .then((res) => {
          this.AllRoles = res.data.data;
          this.$vs.loading.close();
        })
                 .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
    
    },
    getAllUser() {
      this.AllUsers = [];
      this.$vs.loading();
      users
        .getusers(this.page, this.search_word)
        .then((res) => {
          this.AllUsers = res.data.data;
          this.totalPages = res.data.meta.last_page;
          this.$vs.loading.close();
        })
              .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
    
    },
    deleteUser(user_id) {
      this.$vs.loading();
      users
        .deleteSingleUser(user_id)
        .then(() => {
          this.getAllUser("");
          this.$vs.loading.close();
          this.popupDelete = false;
          this.$vs.notify({
            text: "تم حذف العضو بنجاح",
            color: "success",
          });
        })
               .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
    
    },
    SelectUser(user, action) {
      if (action == "delete") {
        this.popupDelete = true;
      } else {
        this.popupAddUser = true;
      }

      this.selectedUser = user;
      this.action = action;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getAllUser("");
    this.getUserRoles();
  },
  created() {
    this.getAllUser("");
    this.getUserRoles();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getAllUser("");
    this.getUserRoles();
  },
};
</script>

