<template lang="html">
  <div class="main-table">
        <v-data-table
        :mobile-breakpoint="0"
            :headers="headersData"
            :items="usersList"
            hide-default-footer
            item-key="name"
            class="elevation-1 text-center"
        >
            <template :mobile-breakpoint="0" v-slot:item="{item}">
                <tr>
                    <td>
                     {{item.name}}</td>
                    <td>
                        {{item.email}}
                    </td>
                     <td class="d-sm-table-cell direction_ltr" >
                        {{item.mobile}}
                    </td>
                     <td>
                       {{item.department}}
                    </td>
                       <td>
                    
                           <div v-if="item.roles.length" 
                         :class="item.roles[0].name=='coordinator'?
                         'Coordinator_label':
                         item.roles[0].name=='admin'?
                         'Adminstrator_label':
                         'viwer_label'">
                         {{item.roles[0].name}}
                         </div>
                    </td>
                       <td >
                         {{item.groups.length?item.groups[0].name:''}}
                    </td>
                    <td class="">
                <div class="d-flex">
                            <button @click="SelectUser(item,'edit')" class="mx-2 btn btn_edit text-center">
                                 <img src="/media/svg/edit_metings.svg" />
                            </button>
                            <button @click="SelectUser(item,'delete')" class="mx-2 btn btn_delete  text-center">
                               <img src="/media/svg/delete.svg" />
                            </button>
                         </div>
                 
                        
                    </td>
                </tr>
            </template>
        
        </v-data-table>
      
  </div>
</template>
<script>
export default {
  props: ["usersList", "SelectUser"],
  data() {
    return {
      headersData: [
        {
          text: "الإسم",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "البريد الإلكترونى", value: "email", sortable: true },
        { text: "الموبايل", value: "phone", sortable: true },
        { text: "الإدارة", value: "department", sortable: true },
        { text: "نوع العضوية", value: "role", sortable: true },
        { text: "المجموعات", value: "groups", sortable: true },
        { text: "", class: "table_end", value: "actions", sortable: false },
      ],
    };
  },
};
</script>